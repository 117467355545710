import styled from 'styled-components';
import Ag from '@brightlive/shared/styles/typography-v3';

const S: Record<string, React.ElementType> = {};

S.PrivateSessionModal = styled.div`
  overflow-y: scroll;
  padding-right: 1px; // fix for weird safari right side cut off

  &::-webkit-scrollbar {
    display: none;
  }
`;

S.AlertWrapper = styled.div`
  margin-bottom: ${props => props.theme.spacing.MD};
`;

S.Title = styled(Ag.HeadingMD)`
  margin-bottom: ${props => props.theme.spacing.XL};
  text-align: center;
`;

S.Description = styled(Ag.ParagraphMD)`
  margin-bottom: ${props => props.theme.spacing.XL};
`;

S.InputWrapper = styled.div`
  margin-bottom: ${props => props.theme.spacing.LG};
`;

S.RadioWrapper = styled.div`
  margin-bottom: ${props => props.theme.spacing.MD};
`;

S.RadioTextInput = styled.div`
  padding-left: 36px;
  margin-top: ${props => props.theme.spacing.MD};
`;

S.Section = styled.div`
  margin-bottom: ${props => props.theme.spacing.XL};
`;

S.SectionTitle = styled(Ag.HeadingXS)`
  margin-bottom: ${props => props.theme.spacing.SM};
`;

S.Terms = styled(Ag.ParagraphXS)``;

S.Link = styled(Ag.Link)``;

export default S;
