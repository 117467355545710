import styled, {DefaultTheme} from 'styled-components';
import {motion} from 'framer-motion';
import {media} from '@brightlive/shared/styles/breakpoints-v3';
import Ag from '@brightlive/shared/styles/typography-v3';

const S: Record<string, React.ElementType> = {};

S.ToastWrapper = styled(motion.div)`
  position: fixed;
  top: 100vh;
  left: 50%;
  transform: translateX(-50%);
  z-index: 20000;
  width: calc(100vw - (${props => props.theme.margin.mobile.page} * 2));
  display: flex;
  justify-content: center;

  ${media.MD} {
    width: auto;
  }
`;

S.Toast = styled.div(
  (props: {type: string; theme: DefaultTheme}) => `
  padding: ${props.theme.spacing.SM} ${props.theme.spacing.MD};
  background: ${
    props.type === 'negative'
      ? props.theme.backgroundColor.negative
      : props.theme.backgroundColor.inversePrimary
  };
  box-shadow: ${props.theme.elevation.level3};
  border-radius: ${props.theme.borderRadius.MD};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  max-width: calc(100vw - (${props.theme.margin.mobile.page} * 2));

  ${media.MD} {
    max-width: none;
  }
  `
);

S.Icon = styled.div`
  margin-right: ${props => props.theme.spacing.XS};
`;

S.Text = styled(Ag.ParagraphMD)(
  (props: {$showIcon: boolean; theme: DefaultTheme}) => `
  color: ${props.theme.contentColor.inverse};
  max-width: calc(100vw - (${props.theme.margin.mobile.page} * 2) - ${
    props.$showIcon ? props.theme.spacing['3XL'] : 0
  });
`
);

export default S;
