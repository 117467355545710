import React from 'react';
import Link from 'next/link';
// Icons
import Instagram from '@brightlive/shared/icons/Instagram';
import Facebook from '@brightlive/shared/icons/Facebook';
import Twitter from '@brightlive/shared/icons/Twitter';
import LinkedIn from '@brightlive/shared/icons/LinkedIn';
import YouTube from '@brightlive/shared/icons/YouTube';
import TikTok from '@brightlive/shared/icons/TikTok';
// Components
import {Grid, Row, Col} from '@brightlive/shared/components/Grid';
// Style
import S from './style';

declare global {
  interface Window {
    Osano: any; // eslint-disable-line
  }
}

const Footer = () => {
  const handleCookieClick = () => {
    window?.Osano?.cm?.showDrawer('osano-cm-dom-info-dialog-open');
  };

  const currentYear = () => {
    const dateToday = new Date();
    return dateToday.getFullYear();
  };

  return (
    <S.Footer>
      <Grid>
        <Row>
          <Col>
            <S.FooterContent>
              <S.DesktopRightContent>
                <S.Copyright>
                  &copy; {currentYear()} Bright Live Inc.
                </S.Copyright>
                <S.Links>
                  <S.Link
                    as="a"
                    href="https://support.brightlive.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Help"
                  >
                    Help
                  </S.Link>
                  <S.LinkSpacer>·</S.LinkSpacer>
                  <Link href="/privacy-policy" passHref>
                    <S.Link as="a" title="Privacy Policy">
                      Privacy Policy
                    </S.Link>
                  </Link>
                  <S.LinkSpacer>·</S.LinkSpacer>
                  <Link href="/terms-of-service">
                    <S.Link as="a" title="Terms of Service">
                      Terms of Service
                    </S.Link>
                  </Link>
                  <S.LinkSpacer>·</S.LinkSpacer>
                  <S.Link>
                    <span onClick={handleCookieClick} title="Cookies">
                      Cookies
                    </span>
                  </S.Link>
                </S.Links>
              </S.DesktopRightContent>
              <S.Icons>
                <S.Icon
                  as="a"
                  href="https://twitter.com/brightlive"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Twitter contentColor="subdued" size={16} />
                </S.Icon>

                <S.Icon
                  as="a"
                  href="https://www.linkedin.com/company/brightlivedotcom"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkedIn contentColor="subdued" size={16} />
                </S.Icon>

                <S.Icon
                  as="a"
                  href="https://www.youtube.com/c/BrightLiveDotCom"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <YouTube contentColor="subdued" size={16} />
                </S.Icon>

                <S.Icon
                  as="a"
                  href="https://www.instagram.com/brightlivedotcom"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Instagram contentColor="subdued" size={16} />
                </S.Icon>

                <S.Icon
                  as="a"
                  href="https://www.tiktok.com/@brightlivedotcom"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TikTok contentColor="subdued" size={16} />
                </S.Icon>

                <S.Icon
                  as="a"
                  href="https://www.facebook.com/brightlivedotcom"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Facebook contentColor="subdued" size={16} />
                </S.Icon>
              </S.Icons>
            </S.FooterContent>
          </Col>
        </Row>
      </Grid>
    </S.Footer>
  );
};

export default React.memo(Footer);
