import React, {useMemo} from 'react';
// Collection
import Button from '@brightlive/shared/components/Button';
// Icon
import Check from '@brightlive/shared/icons/Check';
// Hooks
import {useWindowSize} from '@brightlive/shared/hooks/useWindowSize';
// Style
import S from './style';
import {useSelector} from 'react-redux';
import {UIReducer} from 'redux/ui/reducer';

interface SignUpContentAboutProps {
  scrollToForm: Function;
}

const SignUpContentAbout = ({scrollToForm}: SignUpContentAboutProps) => {
  const signup = useSelector((state: UIReducer) => state.ui.signup);
  const {isMobile} = useWindowSize();

  const List = useMemo(() => {
    return signup.points.map(point => {
      return (
        <S.ListItem key={point}>
          <S.Check>
            <Check size={24} contentColor="default" />
          </S.Check>
          <S.ListItemText>{point}</S.ListItemText>
        </S.ListItem>
      );
    });
  }, [signup.points]);

  return (
    <S.SignUpContentAbout>
      <S.Title>{signup.title}</S.Title>
      <S.Subtitle>{signup.subtitle}</S.Subtitle>
      {isMobile && (
        <S.Button>
          <Button
            text="Get started for free"
            size="medium"
            type="lightning"
            width="100%"
            onClick={scrollToForm}
          />
        </S.Button>
      )}
      <S.List>{List}</S.List>
    </S.SignUpContentAbout>
  );
};

export default SignUpContentAbout;
