import React from 'react';
// Components
import Button from '@brightlive/shared/components/Button';
import Divider from '@brightlive/shared/components/Divider';
// Styles
import S from '../style';

export interface ForgotPasswordSentContentProps {
  handleResetPassword: () => void;
  resetPasswordValue: string;
}

const ForgotPasswordSentContent = ({
  resetPasswordValue,
  handleResetPassword,
}: ForgotPasswordSentContentProps) => {
  return (
    <>
      <S.Title>Forgot Password</S.Title>
      <S.DescriptionLeft>
        A link to reset your password has been sent to your email address,{' '}
        {resetPasswordValue}.
        <br />
        <br />
        If it doesn’t appear within a few minutes, check your spam folder or try
        again.
      </S.DescriptionLeft>
      <Button
        text="Resend email"
        type="secondary"
        size="medium"
        width="100%"
        onClick={handleResetPassword}
      />
      <S.Divider>
        <Divider />
      </S.Divider>
      <S.AltText>
        Still having problems?{' '}
        <S.Link
          href="https://support.brightlive.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact support
        </S.Link>
      </S.AltText>
    </>
  );
};

export default ForgotPasswordSentContent;
