import styled from 'styled-components';
import {media} from '@brightlive/shared/styles/breakpoints-v3';
import Ag from '@brightlive/shared/styles/typography-v3';

const S = {
  Modal: styled.div`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${props => props.theme.backgroundColor.white};
    z-index: 3000;
    overflow: hidden;
  `,

  ModalWrapper: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${props => props.theme.spacing['2XL']}
      ${props => props.theme.spacing['2XL']} ${props => props.theme.spacing.XL};
    box-sizing: border-box;

    &::before {
      content: '';
      position: absolute;
      top: -8px;
      left: 0;
      width: 100%;
      height: 8px;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
      z-index: 100;
    }

    ${media.MD} {
      padding: ${props => props.theme.spacing.LG};
      align-items: center;
      flex-direction: row;
    }
  `,

  CheckboxWrapper: styled.div`
    margin-bottom: ${props => props.theme.spacing.LG};

    ${media.MD} {
      margin-bottom: 0;
    }
  `,

  TermsLink: styled(Ag.Link)``,

  ButtonWrapper: styled.div`
    margin-top: ${props => props.theme.spacing['2XS']};

    ${media.MD} {
      margin-top: 0;
      margin-left: ${props => props.theme.spacing.XL};
    }
  `,
};
export default S;
