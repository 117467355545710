import styled, {DefaultTheme} from 'styled-components';
import {media} from '@brightlive/shared/styles/breakpoints-v3';
import {AuthPages} from '@brightlive/shared/helpers/interfaces';

const getBackgroundColor = (page: AuthPages, theme: DefaultTheme) => {
  if (page === 'signup') {
    return theme.color.brown['3'];
  }
  if (page === 'reset-password') {
    return theme.color.pink['300'];
  }
  if (page === 'login') {
    return theme.color.blue['300'];
  }
  return 'transparent';
};

const S = {
  Page: styled.div(
    (props: {$pageHeight: number; $isSignup: boolean; theme: DefaultTheme}) => `
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1999;
  display: ${props.$isSignup ? 'flex' : 'unset'};
  flex-direction: ${props.$isSignup ? 'column-reverse' : 'unset'};
  background: ${props.theme.backgroundColor.body};
  padding-top: ${props.theme.sizing.mobile.navHeight};
  min-height: ${props.$pageHeight}px;
  width: 100vw;
  box-sizing: border-box;

  ${media.MD} {
    padding-top: ${props.theme.sizing.desktop.navHeight};
    min-height: 100vh;
  }
`
  ),

  Content: styled.div`
    ${media.MD} {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  `,

  ModalContent: styled.div`
    text-align: center;
  `,

  CenteredContent: styled.div`
    padding: ${props => props.theme.spacing['3XL']} 0
      ${props => props.theme.spacing['5XL']};
    text-align: center;

    ${media.MD} {
      padding: 130px 0;
    }
  `,

  LeftContentInner: styled.div`
    text-align: left;
    padding: ${props => props.theme.spacing['2XL']} 0
      ${props => props.theme.spacing['5XL']};

    ${media.MD} {
      padding: ${props => props.theme.spacing['3XL']} 0
        ${props => props.theme.spacing['5XL']};
    }
  `,

  Breadcrumb: styled.div`
    margin-bottom: -${props => props.theme.spacing['3XL']};
  `,

  BackgroundWrapper: styled.div(
    (props: {
      $page: 'login' | 'signup' | 'reset-password';
      theme: DefaultTheme;
    }) => `
  display: ${props.$page === 'signup' ? 'flex' : 'none'};
  flex-direction: column;
  justify-content: flex-end;
  background-color: ${getBackgroundColor(props.$page, props.theme)};

  ${media.MD} {
    display: flex;
    position: absolute;
    width: 50vw;
    top: 0;
    right: 0;
    height: 100%;
    min-height: 100vh;
  }
`
  ),

  Background: styled.img`
    display: none;

    ${media.MD} {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
  `,
};
export default S;
