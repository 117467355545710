import React, {useEffect, useState, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Link from 'next/link';
import Api from 'library/api';
// Components
import Radio from '@brightlive/shared/components/Radio';
import TextInput from '@brightlive/shared/components/TextInput';
import Modal from '@brightlive/shared/components/Modal';
import TextArea from '@brightlive/shared/components/TextArea';
import Alert from '@brightlive/shared/components/Alert';
// Redux
import {toggleToast, togglePrivateSessionModal} from 'redux/ui/actions';
import {AuthReducer} from 'redux/auth/reducer';
import {UIReducer} from 'redux/ui/reducer';
// Helpers
import {
  formatPhoneInput,
  formatPhoneText,
} from '@brightlive/shared/helpers/formatting';
import {validatePhoneNumber} from '@brightlive/shared/helpers/validators/isPhoneNumber';
import {validateEmail} from '@brightlive/shared/helpers/validators/isEmail';
// Style
import S from './style';
interface Errors {
  key: string;
  value: string;
}

const PrivateSessionModal = () => {
  const dispatch = useDispatch();

  const errorRef = useRef<HTMLDivElement>(null);

  const currentUser = useSelector(
    (state: AuthReducer) => state.auth.currentUser
  );

  const privateSessionModal = useSelector(
    (state: UIReducer) => state.ui.privateSessionModal
  );

  const authToken =
    useSelector((state: AuthReducer) => state.auth.auth.authToken) || '';

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [numberOfAttendees, setNumberOfAttendees] = useState('');
  const [occasion, setOccasion] = useState('');
  const [occasionNotes, setOccasionNotes] = useState('');
  const [notes, setNotes] = useState('');
  const [errors, setErrors] = useState<Array<Errors>>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (privateSessionModal.toggled) {
      if (currentUser?.firstName && currentUser?.lastName) {
        setName(`${currentUser?.firstName} ${currentUser?.lastName}`);
      }
      if (currentUser?.email) {
        setEmail(currentUser?.email);
      }
      if (currentUser?.phone) {
        setPhone(formatPhoneText(currentUser?.phone));
      }
    }
  }, [currentUser, privateSessionModal]);

  const closeModal = () => {
    dispatch(togglePrivateSessionModal(false, ''));
    clearForm();
  };

  const clearForm = () => {
    setEmail('');
    setName('');
    setPhone('');
    setNumberOfAttendees('');
    setOccasion('');
    setOccasionNotes('');
    setNotes('');
    setErrors([]);
  };

  const handleOccasionChange = (occasionType: string) => {
    setOccasion(occasionType);
    setOccasionNotes('');
  };

  const formatOccasion = () => {
    const occasions = {
      work: 'Work Event',
      social: 'Social Event',
      public: 'Public Event',
      other: 'Other',
    };

    if (['social', 'public'].includes(occasion)) {
      return occasions[occasion];
    } else {
      return `${occasions[occasion]} - ${occasionNotes}`;
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    removeError('server');
    // check for validation errors
    const hasErrors = checkForErrors();
    if (hasErrors) {
      setLoading(false);
      return;
    }
    // if no errors - post to api
    const formattedOccasion = formatOccasion();
    try {
      await Api.post('/private-session', {
        name,
        email,
        phone,
        numberOfAttendees,
        occasion: formattedOccasion,
        details: notes,
        creator: privateSessionModal.creatorDisplayName,
      }).execute(authToken);
      // on success - stop loading, dispatch toast, and close modal
      setLoading(false);
      dispatch(toggleToast(true, 'Request submitted'));
      closeModal();
    } catch (err) {
      // on server error, stop loading, show server error, and scroll to server error
      setLoading(false);
      const serverError = {
        key: 'server',
        value: `${err}`,
      };
      setErrors([serverError]);
      if (errorRef.current) {
        errorRef.current.scrollIntoView({behavior: 'smooth'});
      }
    }
  };

  const checkForErrors = () => {
    const newErrors: Errors[] = [];
    if (phone && !validatePhoneNumber(phone)) {
      newErrors.push({
        key: 'phone',
        value: 'Please enter a valid phone number',
      });
    }
    if (!validateEmail(email)) {
      newErrors.push({key: 'email', value: 'Please enter a valid email'});
    }
    setErrors(newErrors);
    return newErrors.length;
  };

  const getError = key => {
    const inputError = errors.find(e => e.key === key);
    return inputError?.value || '';
  };

  const removeError = (key: string) => {
    const oldErrors = [...errors];
    // Remove error for this key
    const index = oldErrors.findIndex(error => error.key === key);
    if (index > -1) {
      oldErrors.splice(index, 1);
      setErrors(oldErrors);
    }
  };

  const onEmailChange = e => {
    e.preventDefault();
    setEmail(e.target.value);
    removeError('email');
  };

  const onPhoneChange = e => {
    e.preventDefault();
    const newValue = formatPhoneInput(e.target.value, phone);
    setPhone(newValue);
    removeError('phone');
  };

  const title = 'Book a private group session';
  const subtitle = 'Thank you for your interest in a private group session';

  const content = (
    <>
      <S.Section>
        <S.SectionTitle>What’s the occasion?</S.SectionTitle>
        <S.RadioWrapper>
          <Radio
            label="Work, company, team event"
            onCheck={() => {
              handleOccasionChange('work');
            }}
            checked={occasion === 'work'}
          />
          {occasion === 'work' && (
            <S.RadioTextInput>
              <TextInput
                label="Business or organization name"
                onChange={e => {
                  setOccasionNotes(e.target.value);
                }}
                value={occasionNotes}
              />
            </S.RadioTextInput>
          )}
        </S.RadioWrapper>
        <S.RadioWrapper>
          <Radio
            label="Social event (e.g. birthday parties, hangouts, etc.)"
            onCheck={() => {
              handleOccasionChange('social');
            }}
            checked={occasion === 'social'}
          />
        </S.RadioWrapper>
        <S.RadioWrapper>
          <Radio
            label="Public event (e.g. charity, club, conference, etc.)"
            onCheck={() => {
              handleOccasionChange('public');
            }}
            checked={occasion === 'public'}
          />
        </S.RadioWrapper>
        <Radio
          label="Other"
          onCheck={() => {
            handleOccasionChange('other');
          }}
          checked={occasion === 'other'}
        />
        {occasion === 'other' && (
          <S.RadioTextInput>
            <TextInput
              onChange={e => {
                setOccasionNotes(e.target.value);
              }}
              value={occasionNotes}
            />
          </S.RadioTextInput>
        )}
      </S.Section>
      <S.Section>
        <S.SectionTitle>
          Tell us anything else about your session
        </S.SectionTitle>
        <TextArea
          placeholder="e.g. when you would like to have your session, estimated attendance and budget, etc."
          onChange={(value: string) => {
            setNotes(value);
          }}
          value={notes}
        />
      </S.Section>
    </>
  );

  const submitDisabled = !name || !email || !occasion;

  if (privateSessionModal.toggled) {
    return (
      <Modal
        closeModal={closeModal}
        desktopHeight="90vh"
        mobileHeight="100%"
        buttons={{
          button1Text: 'Cancel',
          button2Text: 'Submit',
          button2OnClick: handleSubmit,
          button2Disabled: submitDisabled,
          button2Loading: loading,
        }}
      >
        <S.PrivateSessionModal>
          <div ref={errorRef}>
            {getError('server') && (
              <S.AlertWrapper>
                <Alert
                  text="Aww, dang! There was a connection issue. Try again."
                  type="critical"
                />
              </S.AlertWrapper>
            )}
          </div>
          <S.Title>{title}</S.Title>
          <S.Description>
            {subtitle}
            {privateSessionModal.creatorDisplayName
              ? ` with
        ${privateSessionModal.creatorDisplayName}`
              : ''}
            ! <br />
            <br />
            Let us know a little bit about what you have in mind and one of our
            Bright coordinators will follow with up you within 2 business days.
          </S.Description>
          <S.Section>
            <S.SectionTitle>What’s your contact information?</S.SectionTitle>
            <S.InputWrapper>
              <TextInput
                label="Name"
                onChange={e => {
                  setName(e.target.value);
                }}
                value={name}
              />
            </S.InputWrapper>
            <S.InputWrapper>
              <TextInput
                label="Email"
                onChange={e => {
                  onEmailChange(e);
                }}
                value={email}
                error={getError('email')}
              />
            </S.InputWrapper>
            <TextInput
              label="Phone number (optional)"
              onChange={e => {
                onPhoneChange(e);
              }}
              value={phone}
              error={getError('phone')}
            />
          </S.Section>
          {content}
          <S.Terms>
            By selecting “Submit,” I agree I am at least 18-years-old or
            16-years-old with a parent's permission. I have read and agree to
            Bright's{' '}
            <Link href="/terms-of-service" passHref>
              <S.Link target="_blank" rel="noreferrer">
                Terms of Service
              </S.Link>
            </Link>{' '}
            and{' '}
            <Link href="/privacy-policy" passHref>
              <S.Link target="_blank" rel="noreferrer">
                Privacy Policy
              </S.Link>
            </Link>
            .
          </S.Terms>
        </S.PrivateSessionModal>
      </Modal>
    );
  } else {
    return null;
  }
};

export default React.memo(PrivateSessionModal);
