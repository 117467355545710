import React, {useMemo} from 'react';
import {useSelector, useDispatch} from 'react-redux';
// Components
import Toast from '@brightlive/shared/components/Toast';
// Redux
import {UIReducer} from 'redux/ui/reducer';
import {toggleToast} from 'redux/ui/actions';
import {ThemeProvider} from 'styled-components';
import {theme} from '@brightlive/shared/styles/theme';
import {themeDark} from '@brightlive/shared/styles/theme-dark';

const ToastWrapper = ({pageTheme}: {pageTheme?: 'light' | 'dark'}) => {
  const dispatch = useDispatch();
  const toast = useSelector((state: UIReducer) => state.ui.toast);
  const sessionTheme = useSelector((state: UIReducer) => state.ui.sessionTheme);
  const hideToast = () => {
    dispatch(toggleToast(false, '', '', '', toast.placement));
  };

  const getTheme = useMemo(() => {
    if (pageTheme) {
      return pageTheme === 'light' ? theme : themeDark;
    } else if (sessionTheme) {
      return sessionTheme === 'light' ? theme : themeDark;
    } else {
      return 'light';
    }
  }, [pageTheme, sessionTheme]);

  return (
    <ThemeProvider theme={getTheme}>
      <Toast
        text={toast.text}
        icon={toast.icon}
        toggled={toast.toggled}
        type={toast.toastType}
        placement={toast.placement}
        hideToast={hideToast}
      />
    </ThemeProvider>
  );
};

export default React.memo(ToastWrapper);
