import * as Sentry from '@sentry/nextjs';
import firebase from 'firebase/compat/app';

export const validPassword = (password: string) => {
  return password?.length >= 8;
};

// Check if old password is correct
export const isOldPasswordValid = async currentPassword => {
  try {
    const user = firebase.auth().currentUser;
    if (!user || !user?.email) {
      throw new Error('');
    }
    // Get credential
    const credential = firebase.auth.EmailAuthProvider.credential(
      user.email,
      currentPassword
    );
    await user.reauthenticateWithCredential(credential);
    return true;
  } catch (err) {
    Sentry.captureException(err);
    return false;
  }
};
