import React from 'react';
// Components
import Button from '@brightlive/shared/components/Button';
import TextInput from '@brightlive/shared/components/TextInput';
import Divider from '@brightlive/shared/components/Divider';
// Helpers
import {validateEmail} from '@brightlive/shared/helpers/validators/isEmail';
import {
  LoadingAuthButton,
  AuthPages,
} from '@brightlive/shared/helpers/interfaces';
// Images
import Google from 'public/images/google.svg';
// Styles
import S from '../style';

export interface LoginContentProps {
  loading: LoadingAuthButton;
  handleSocialLogin: (provider: 'google' | 'apple') => Promise<void>;
  handleEmailLogin: () => Promise<void>;
  setPage: (val: AuthPages) => void;
  emailValue: string;
  setEmailValue: (val: string) => void;
  passwordValue: string;
  setPasswordValue: (val: string) => void;
  emailError: string;
  setEmailError: (val: string) => void;
  isCreator?: boolean;
}

const LoginContent = ({
  loading,
  setPage,
  emailValue,
  setEmailValue,
  emailError,
  setEmailError,
  passwordValue,
  setPasswordValue,
  handleEmailLogin,
  handleSocialLogin,
}: LoginContentProps) => {
  const onEmailChange = e => {
    e.preventDefault();
    setEmailValue(e.target.value);
    setEmailError('');
  };

  const onPasswordChange = e => {
    e.preventDefault();
    setPasswordValue(e.target.value);
  };

  const checkForErrors = () => {
    if (!validateEmail(emailValue)) {
      setEmailError('Please enter a valid email');
      return;
    }
    handleEmailLogin();
  };

  const disabled = !emailValue || !passwordValue;

  return (
    <>
      <S.Title>Log in to Bright</S.Title>
      <S.Input>
        <TextInput
          label="Email"
          onChange={onEmailChange}
          value={emailValue}
          error={emailError}
        />
      </S.Input>
      <S.Input>
        <TextInput
          label="Password"
          type="password"
          onChange={onPasswordChange}
          value={passwordValue}
          keyPressHandler={checkForErrors}
        />
      </S.Input>

      <S.ForgotPassword
        onClick={() => {
          setPage('forgot-password');
        }}
      >
        <S.Link>Forgot Password?</S.Link>
      </S.ForgotPassword>

      <Button
        text="Log in"
        type="primary"
        size="medium"
        width="100%"
        disabled={disabled}
        loading={loading === 'email'}
        onClick={checkForErrors}
      />
      <S.Divider>
        <Divider />
        <S.Or>or</S.Or>
      </S.Divider>
      <S.GoogleButton>
        <Button
          text="Continue with Google"
          type="secondary"
          size="medium"
          width="100%"
          imgIcon={Google}
          onClick={() => handleSocialLogin('google')}
          loading={loading === 'google'}
        />
      </S.GoogleButton>
      <S.AppleButton>
        <Button
          text="Continue with Apple"
          type="secondary"
          size="medium"
          width="100%"
          icon="Apple"
          onClick={() => handleSocialLogin('apple')}
          loading={loading === 'apple'}
        />
      </S.AppleButton>
      <S.Terms>
        By selecting logging in, I agree to Bright’s{' '}
        <S.Link
          target="_blank"
          href="/terms-of-service"
          rel="noopener noreferrer"
        >
          Terms & Conditions
        </S.Link>{' '}
        and acknowledge the{' '}
        <S.Link
          target="_blank"
          href="/privacy-policy"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </S.Link>
        .
      </S.Terms>
      <S.AltText>
        New to Bright?{' '}
        <S.Link
          onClick={() => {
            setPage('signup');
          }}
        >
          Sign up
        </S.Link>
      </S.AltText>
    </>
  );
};

export default LoginContent;
