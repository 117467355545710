import React from 'react';
// Components
import Button from '@brightlive/shared/components/Button';
import TextInput from '@brightlive/shared/components/TextInput';
// Styles
import S from '../style';

export interface ForgotPasswordContentProps {
  handleResetPassword: () => void;
  resetPasswordError: string;
  resetPasswordValue: string;
  setResetPasswordValue: (val: string) => void;
  setResetPasswordError: (val: string) => void;
  loading: string;
}

const ForgotPasswordContent = ({
  handleResetPassword,
  resetPasswordError,
  resetPasswordValue,
  setResetPasswordValue,
  setResetPasswordError,
  loading,
}: ForgotPasswordContentProps) => {
  const onResetPasswordChange = e => {
    e.preventDefault();
    const newValue = e.target.value;
    setResetPasswordValue(newValue);
    setResetPasswordError('');
  };

  return (
    <>
      <S.Title>Forgot Password</S.Title>
      <S.DescriptionLeft>
        Enter the email you used to sign up for your account and we’ll send you
        a special link.
      </S.DescriptionLeft>
      <S.Input>
        <TextInput
          label="Email"
          onChange={onResetPasswordChange}
          value={resetPasswordValue}
          error={resetPasswordError}
        />
      </S.Input>
      <S.ContinueButton>
        <Button
          text="Submit"
          type="secondary"
          size="medium"
          width="100%"
          disabled={!resetPasswordValue}
          loading={loading === 'reset-password'}
          onClick={handleResetPassword}
        />
      </S.ContinueButton>
    </>
  );
};

export default ForgotPasswordContent;
