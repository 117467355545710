import React, {useState} from 'react';
import firebase from 'firebase/compat/app';
import {getAuthErrorMessage} from '@brightlive/shared/helpers/auth';
import {validateEmail} from '@brightlive/shared/helpers/validators/isEmail';
import Api from 'library/api';
import {validPassword} from 'library/helpers/validators/validPassword';
// Components
import Button from '@brightlive/shared/components/Button';
import TextInput from '@brightlive/shared/components/TextInput';
import Divider from '@brightlive/shared/components/Divider';
// Styles
import S from '../style';

interface SignUpContentProps {
  nameValue: string;
  emailValue: string;
  emailError: string;
  setEmailError: (val: string) => void;
  passwordValue: string;
  setPasswordValue: (val: string) => void;
  handleEmailLogin: () => Promise<void>;
  handleAnonLogin: () => Promise<void>;
  parentLoading: string;
}

const AccountCreation = ({
  nameValue,
  emailValue,
  emailError,
  setEmailError,
  passwordValue,
  setPasswordValue,
  handleEmailLogin,
  handleAnonLogin,
  parentLoading,
}: SignUpContentProps) => {
  // States
  const [passwordValue2, setPasswordValue2] = useState('');
  const [passwordValue2Error, setPasswordValue2Error] = useState('');
  const [loading, setLoading] = useState(false);

  const onPasswordChange = e => {
    e.preventDefault();
    setPasswordValue(e.target.value);
    setPasswordValue2Error('');
  };

  const onPassword2Change = e => {
    e.preventDefault();
    setPasswordValue2(e.target.value);
    setPasswordValue2Error('');
  };

  const checkForErrors = async () => {
    setLoading(true);
    try {
      if (passwordValue === passwordValue2) {
        setLoading(true);
        await handleSignup();
      } else {
        setLoading(false);
        setPasswordValue2Error('Passwords must match');
      }
    } catch (error: unknown) {
      const {code, message} = error as firebase.auth.Error;
      setLoading(false);
      setPasswordValue2Error(getAuthErrorMessage(code, message));
    }
  };

  const handleCreateUser = async () => {
    const data: Record<string, string | boolean> = {
      displayName: nameValue,
      email: emailValue,
      password: passwordValue,
      promotionalEmails: false,
      source: window?.sessionStorage.getItem('signupSource') ?? 'signup',
      sourceSession: window?.sessionStorage.getItem('sessionEntry') ?? '',
    };
    // send data to server to create user
    await Api.post('/users', data).execute();
  };

  const handleSignup = async () => {
    setLoading(true);
    setPasswordValue2Error('');
    let errors = false;

    // Validate that email has value
    if (!validateEmail(emailValue)) {
      setEmailError('Please enter a valid email');
      errors = true;
    }
    // Check is email is unique
    if (validateEmail(emailValue)) {
      const response = await Api.get(
        `/users/unique-email?email=${encodeURIComponent(emailValue)}`
      ).execute();
      // Set error is email is taken
      if (!response.isUnique) {
        setEmailError('This email is already taken');
        errors = true;
      }
    }

    // Validate that password exists
    if (!validPassword(passwordValue)) {
      setPasswordValue2Error('Please enter a valid password');
      errors = true;
    }

    // If everything passes - go to next page
    if (!errors) {
      try {
        // Create user via server
        await handleCreateUser();
        await handleEmailLogin();
      } catch (err) {
        console.error(err);
        const {code, message} = err as firebase.auth.Error;
        setLoading(false);
        setPasswordValue2Error(getAuthErrorMessage(code, message));
      }
    }
    setLoading(false);
  };

  return (
    <>
      <S.Title>Let’s finish creating your account!</S.Title>
      <S.DescriptionLeft>
        We’re excited for you to join Bright! Finish signing up by creating a
        password.
      </S.DescriptionLeft>
      <S.ReadOnly>
        <S.Label>Name</S.Label>
        <S.Value>{nameValue}</S.Value>
      </S.ReadOnly>
      <Divider />
      <S.ReadOnly>
        <S.Label>Email</S.Label>
        <S.Value>{emailValue}</S.Value>
      </S.ReadOnly>
      <Divider />
      <S.InputTop>
        <TextInput
          label="Create new password"
          placeholder=""
          onChange={onPasswordChange}
          value={passwordValue}
          error={emailError}
          type="password"
          instructions="Must have at least 8 characters."
        />
      </S.InputTop>
      <S.Input>
        <TextInput
          label="Confirm password"
          placeholder=""
          onChange={onPassword2Change}
          keyPressHandler={checkForErrors}
          value={passwordValue2}
          error={passwordValue2Error}
          type="password"
        />
      </S.Input>
      <S.CreateAccountButton>
        <Button
          text="Create account"
          type="primary"
          size="medium"
          width="100%"
          onClick={checkForErrors}
          loading={parentLoading === 'email' || loading}
          disabled={
            !passwordValue || !passwordValue2 || parentLoading === 'join'
          }
        />
      </S.CreateAccountButton>
      <S.ContinueButton>
        <Button
          text="Skip"
          type="secondary"
          size="medium"
          width="100%"
          onClick={handleAnonLogin}
          disabled={loading}
          loading={parentLoading === 'join'}
        />
      </S.ContinueButton>
    </>
  );
};

export default AccountCreation;
