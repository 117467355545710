import styled from 'styled-components';
import {media} from '@brightlive/shared/styles/breakpoints-v3';
import Ag from '@brightlive/shared/styles/typography-v3';

const S = {
  Footer: styled.div`
    background: ${props => props.theme.backgroundColor.secondary};
    padding: ${props => props.theme.spacing.MD} 0;
    position: relative;
    z-index: 10;
  `,

  FooterContent: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    ${media.MD} {
      flex-direction: row;
      justify-content: space-between;
    }
  `,

  DesktopRightContent: styled.div`
    ${media.MD} {
      display: flex;
    }
  `,

  Copyright: styled(Ag.ParagraphSM)`
    margin-bottom: ${props => props.theme.spacing.LG};

    ${media.MD} {
      margin: 0 ${props => props.theme.spacing['2XL']} 0 0;
    }
  `,

  Links: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${props => props.theme.spacing.SM};
    margin-bottom: ${props => props.theme.spacing.LG};

    ${media.MD} {
      margin-bottom: 0;
      flex-direction: row;
      align-items: flex-start;
    }
  `,

  Link: styled(Ag.ParagraphSM)`
    color: ${props => props.theme.contentColor.subdued};
    text-decoration: underline;
    cursor: pointer;
  `,

  LinkSpacer: styled(Ag.ParagraphSM)`
    display: none;

    ${media.MD} {
      color: ${props => props.theme.contentColor.subdued};
      display: block;
    }
  `,

  Icons: styled.div`
    display: flex;
    justify-content: center;
    gap: ${props => props.theme.spacing.SM};
  `,

  Icon: styled.div`
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
};

export default S;
