import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Api from 'library/api';
// Components
import Button from '@brightlive/shared/components/Button';
import Checkbox from '@brightlive/shared/components/Checkbox';
// Redux
import {AuthReducer} from 'redux/auth/reducer';
import {getCurrentUser} from 'redux/auth/actions';
// Styles
import S from './style';

const LegalModal = () => {
  const dispatch = useDispatch();
  // Selectors
  const currentUser = useSelector(
    (state: AuthReducer) => state.auth.currentUser
  );
  const authToken =
    useSelector((state: AuthReducer) => state.auth.auth.authToken) || '';
  // State
  const [boxChecked, setBoxChecked] = useState(false);

  const getCheckboxLabel = () => {
    return (
      <>
        I acknowledge Bright's{' '}
        <S.TermsLink
          target="_blank"
          href="/privacy-policy"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </S.TermsLink>{' '}
        and agree to the{' '}
        <S.TermsLink
          target="_blank"
          href="/terms-of-service"
          rel="noopener noreferrer"
        >
          Terms of Service
        </S.TermsLink>
        {'.'}
      </>
    );
  };

  const handleUpdateUser = async () => {
    // send data to server to create user
    await Api.get(`/users/${currentUser.id}/legal-agreement`).execute(
      authToken
    );
    // Fetch current User
    await dispatch(getCurrentUser());
  };
  // Check if user needs to agree to a new terms or privacy
  if (
    currentUser.id &&
    (currentUser?.newPrivacyAvail || currentUser?.newTermsAvail)
  ) {
    return (
      <S.Modal>
        <S.ModalWrapper>
          <S.CheckboxWrapper>
            <Checkbox
              label={getCheckboxLabel()}
              onCheck={() => {
                setBoxChecked(!boxChecked);
              }}
              checked={boxChecked}
            />
          </S.CheckboxWrapper>
          <S.ButtonWrapper>
            <Button
              type="primary"
              size="medium"
              disabled={!boxChecked}
              text="Agree"
              onClick={handleUpdateUser}
            />
          </S.ButtonWrapper>
        </S.ModalWrapper>
      </S.Modal>
    );
  }

  return null;
};

export default React.memo(LegalModal);
