import styled from 'styled-components';
import {media} from '@brightlive/shared/styles/breakpoints-v3';
import Ag from '@brightlive/shared/styles/typography-v3';

const S = {
  SignUpContentAbout: styled.div`
    padding: ${props => props.theme.spacing['2XL']}
      ${props => props.theme.margin.mobile.page}
      ${props => props.theme.spacing['3XL']};

    ${media.MD} {
      padding: 0;
      position: absolute;
      top: calc(
        (50vw - 500px) / 2 + ${props => props.theme.sizing.desktop.navHeight}
      );
      left: 50%;
      transform: translateX(-50%);
      width: 500px;
    }
  `,
  Title: styled(Ag.DisplayMD)`
    color: ${props => props.theme.contentColor.black};
    margin-bottom: ${props => props.theme.spacing.SM};
  `,
  Subtitle: styled(Ag.HeadingMD)`
    color: ${props => props.theme.contentColor.black};
    margin-bottom: ${props => props.theme.spacing['2XL']};
  `,
  List: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: ${props => props.theme.spacing.SM};
  `,
  Button: styled.div`
    margin-bottom: ${props => props.theme.spacing['2XL']};
  `,
  Check: styled.div``,
  ListItem: styled.div`
    display: flex;
  `,
  ListItemText: styled(Ag.ParagraphLG)`
    color: ${props => props.theme.contentColor.black};
    margin-left: ${props => props.theme.spacing.XS};
  `,
};

export default S;
